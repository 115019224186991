<template>
	<div>
        <v-progress-linear :value="percentage" color="green" @click="dialog = true "></v-progress-linear>
    </div>
</template>

<script>
export default {
	name: "PollResults",
	props: {
		answer: { type: String },
		question: { type: String },
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		polls() {
			return this.$store.getters["polls/byQuestion"](this.question);
		},
		total() {
			return this.polls.filter((a) => a.status == "submitted").length;
		},
		count() {
			let count = this.polls.filter((a) =>{ 
                return a.data && a.data[this.question] == this.answer
            })
			return count.length;
		},
        percentage(){
            return (this.count / this.total) * 100;
        }
	},
};
</script>
