<template>
	<mw-dialog
		v-model="dialog"
		small
		submitBtn
		@submit="remind"
		cancelBtn
		:title="$t('collaboration.remind')"
	>
		<template v-slot:button>
			<v-btn :icon="icon" @click="dialog = true" color="primary">
				<v-icon v-if="icon">mdi-email</v-icon>
				<span v-else>{{$t("buttons.send")}}</span>
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			{{$t("collaboration.before_remind")}}
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "RemindColleague",
	props: {
		value: { type: String }, // the ID of the user,
		icon: {type: Boolean }
	},
	data: () => {
		return {
			dialog: false,
			user: null,
		};
	},
	components: {
		MwDialog,
	},
	methods: {
		remind() {
			const self = this;
			self.$store.dispatch("emails/reminder", self.value).then(() => {
				self.dialog = false;
			});
		},
	},
};
</script>
