var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('h2',[_vm._v(_vm._s(_vm.$t("collaboration.my_team")))]),_c('render-content',{attrs:{"id":"teams"}}),_c('p',{staticClass:"text-right"},[_c('mw-dialog',{attrs:{"small":"","title":_vm.$t('collaboration.add_colleague')},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("collaboration.add_colleague"))+" ")])]},proxy:true},{key:"dialog-body",fn:function(){return [_c('add-colleague',{on:{"input":function($event){_vm.dialog = false}}})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1),_c('v-data-table',{attrs:{"id":"address-book","search":_vm.search,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.polls",fn:function(ref){
var item = ref.item;
return [(item.pollCounts.total)?_c('span',[_vm._v(" "+_vm._s(item.pollCounts.outstanding || 0)+" of "+_vm._s(item.pollCounts.total)+" outstanding ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.responses",fn:function(ref){
var item = ref.item;
return [_c('table',_vm._l((item.responses),function(response){return _c('tr',{key:response},[_c('td',[_c('span',{staticClass:"mt-1 pl-3"},[(_vm.rate(response))?_c('v-chip',{staticClass:"pa-1 mr-3",attrs:{"small":"","color":"green","outlined":""}},[_vm._v(" "+_vm._s(_vm.rate(response))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.sectionName(response))+" "),_c('revoke',{staticClass:"ml-2",attrs:{"id":response,"icon":""}})],1)])])}),0)]}},{key:"item.btn1",fn:function(ref){
var item = ref.item;
return [_c('remind-colleague',{attrs:{"icon":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})]}},{key:"item.btn2",fn:function(ref){
var item = ref.item;
return [_c('colleague-details',{attrs:{"icon":""},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"mt-5 lightest--background",attrs:{"color":"lightest"}},[_c('td',[_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('td',{attrs:{"colspan":"4"}},[_c('v-autocomplete',{attrs:{"label":"Filter sections","multiple":"","items":_vm.sectionItems,"chips":"","small-chips":"","clearable":""},model:{value:(_vm.selectedSections),callback:function ($$v) {_vm.selectedSections=$$v},expression:"selectedSections"}})],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }