<template>
	<v-sheet>
		<h2>{{ $t("collaboration.my_team") }}</h2>
		<render-content id="teams"></render-content>
		<p class="text-right">
			<mw-dialog
				v-model="dialog"
				small
				:title="$t('collaboration.add_colleague')"
			>
				<template v-slot:button>
					<v-btn @click="dialog = true" color="primary">
						{{ $t("collaboration.add_colleague") }}
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<add-colleague @input="dialog = false"></add-colleague>
				</template>
			</mw-dialog>
		</p>
		<v-data-table
			id="address-book"
			:search="search"
			:headers="headers"
			:items="items"
		>
			<template v-slot:item.polls="{ item }">
				<span v-if="item.pollCounts.total">
					{{ item.pollCounts.outstanding || 0 }} of
					{{ item.pollCounts.total }} outstanding
				</span>
				<span v-else>
					-
				</span>
			</template>
			<template v-slot:item.responses="{ item }">
				<table>
					<tr v-for="response in item.responses" :key="response">
						<td>
							<span class="mt-1 pl-3">
								<v-chip
									small
									class="pa-1 mr-3"
									v-if="rate(response)"
									color="green"
									outlined
								>
									{{ rate(response) }}
								</v-chip>
								{{ sectionName(response) }}
								<revoke class="ml-2" :id="response" icon></revoke>
							</span>
						</td>
					</tr>
				</table>
			</template>

			<template v-slot:item.btn1="{ item }">
				<remind-colleague icon v-model="item.id"></remind-colleague>
			</template>
			<template v-slot:item.btn2="{ item }">
				<colleague-details icon v-model="item.id"></colleague-details>
			</template>
			<template v-slot:body.append>
				<tr color="lightest" class="mt-5 lightest--background">
					<td>
						<v-text-field
							placeholder="Search"
							v-model="search"
						></v-text-field>
					</td>
					<td colspan="4">
						<v-autocomplete
							label="Filter sections"
							v-model="selectedSections"
							multiple
							:items="sectionItems"
							chips
							small-chips
							clearable
						>
						</v-autocomplete>
					</td>
				</tr>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<style lang="less">
#address-book .text-start {
	vertical-align: top !important;
	padding-top: 10px !important;
}
</style>
<script>
import AddColleague from "@c/collaboration/AddColleague";
import RemindColleague from "@c/collaboration/RemindColleague";
import ColleagueDetails from "@c/collaboration/ColleagueDetails";
import Revoke from "@c/collaboration/Revoke";
import MwDialog from "@c/ui/MwDialog.vue";
import RenderContent from "@c/ui/RenderContent";
export default {
	name: "AddressBook",
	data: () => {
		return {
			search: "",
			dialog: false,
			expanded: false,
			selectedSections: [],
		};
	},
	computed: {
		assessment() {
			return this.$store.getters["assessment/id"];
		},
		polls() {
			return this.$store.state.polls.data;
		},
		sections() {
			return this.$store.state.sections.data;
		},
		sectionItems() {
			return this.mwutils.itemsArray(this.sections);
		},
		sectionResponses() {
			return this.$store.state.sectionResponses.data;
		},
		users() {
			return this.$store.getters["users/respondents"];
		},
		items() {
			const self = this;
			var users = Object.values(this.users);
			users = users.map((u) => {
				u.responses = self.userResponses(u.id);
				u.polls = self.userPolls(u.id);
				u.pollCounts = self.countPolls(u.polls);
				return u;
			});
			if (self.selectedSections.length) {
				users = users.filter((u) => {
					return u.responses.find((r) => {
						let section = self.sectionResponses[r].section;
						return self.selectedSections.includes(section);
					});
				});
			}
			return users;
		},
		headers() {
			return [
				{ text: "Name", value: "displayName" },
				{ text: "Sections", value: "responses" },
				{ text: "Polls", value: "polls" },
				{ value: "btn1", width: "5%" },
				{ value: "btn2", width: "5%" },
			];
		},
	},
	methods: {
		userPolls(user) {
			const self = this;
			return Object.values(self.polls)
				.filter((poll) => poll.user == user)
				.map((poll) => poll.id);
		},
		userResponses(user) {
			const self = this;
			return Object.values(self.sectionResponses)
				.filter((response) => response.users[user])
				.map((response) => response.id);
		},
		countPolls(polls) {
			const self = this;
			let total = polls.length;
			let submitted = polls.filter(
				(p) => self.polls[p].status == "submitted"
			).length;
			let outstanding = total - submitted;
			return { total, submitted, outstanding };
		},
		rate(response) {
			let rate = this.sectionResponses[response].completion_rate || 0;
			return rate.toFixed(2) + "%";
		},
		sectionName(r) {
			const self = this;
			let response = self.sectionResponses[r];
			let section = self.sections[response.section];
			if (section) {
				return section.name;
			}
			return;
		},
	},
	watch: {
		assessment(value) {
			if (value) {
				this.$store.dispatch("polls/allPolls");
			}
		},
	},
	components: {
		MwDialog,
		AddColleague,
		Revoke,
		ColleagueDetails,
		RemindColleague,
		RenderContent,
	},
};
</script>
