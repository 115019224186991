<template>
	<v-sheet>
        <template v-if="poll">

        <render-content id="pollintro"></render-content>
        <template v-if="!saved">
            <questions
                v-if="question"
                poll
                :ids="[question.id]"
                v-model="data"
            ></questions>
            <p class="text-right">
                <v-btn @click="submit" color="primary">{{ $t("buttons.submit") }}</v-btn>
            </p>
        </template>  
        <template v-else>
            <v-alert type="info" text>
                {{$t("collaboration.polls.thank_you")}}
            </v-alert>
        </template>
        </template>
        <template v-else>
                {{$t("collaboration.polls.not_found")}}

        </template>
	</v-sheet>
</template>

<script>
import RenderContent from "@c/ui/RenderContent"
import Questions from "@c/survey/render/Questions"
    export default {
        name: "Poll",
        props: {
            id: {type: String }
        },
        data: () => {
            return {
                data: {}, 
                saved: false
            }
        },
        computed: {
            poll(){
                return this.$store.state.polls.data[this.id];
            },
            question(){
                if( !this.poll ){
                    return;
                }
                return this.$store.state.questions.data[this.poll.question];
            },

        },
        components: {
            Questions,
            RenderContent,

        },
        methods: {
            submit(){
                this.$store.dispatch("polls/patch", {id: this.id, data: this.data, status: "submitted"}).then( () => {
                    this.saved = true;
                })
            }
        },
        watch: {
            poll(){
                this.$store.dispatch("questions/fetchById", this.poll.question );
            }
        },
        created(){
            this.$store.dispatch("polls/fetchById", this.id )
        }
    }
</script>
