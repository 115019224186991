<template>
	<v-sheet color="transparent">
		<v-text-field
			:label="$t('profile.display_name')"
			v-model="displayName"
		></v-text-field>
		<!-- <v-text-field
			v-model="email"
			:label="$t('misc.email')"
			@input="(v) => (email = parseEmail(v))"
			:rules="[
				(v) => mwutils.validateEmail(v) || $t('errors.invalid_email'),
			]"
		></v-text-field> -->
        <p class="text-right">
            <v-btn color="primary" @click="update">{{$t("buttons.save")}}</v-btn>
        </p>
	</v-sheet>
</template>

<script>
export default {
    name: "Profile",
    data: () => {
        return  {
            displayName: "",
            email: "",
        }
    },
    computed: {
        user(){
            return this.$store.state.auth.data
        }
    },
    methods: {
        update(){
            const self = this;
            self.$store.dispatch("auth/patch", {
                displayName: self.displayName,
                // email: self.email
            } ).then( () => {
                self.saved = true;
            })
        }, 
        parseEmail(r){
            return r.toLowerCase().trim();
        }
    },
    created() {
        this.displayName = this.user.displayName;
        this.email = this.user.email;
    }
}
</script>
