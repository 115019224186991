<template>
	<v-sheet flat>
		<render-poll v-for="poll in polls" :key="poll.id" :id="poll.id"> </render-poll>
        <v-alert color="lightest" v-if="!polls.length">
            No active polls
        </v-alert>
	</v-sheet>
</template>

<script>

import RenderPoll from "@c/collaboration/RenderPoll"
export default {
    name: "Polls",
    computed: {
        polls() {
            let polls = this.$store.getters["polls/myPolls"]
            return polls.filter( poll => poll.status !== "submitted") 
        }
    },
    components: {
        RenderPoll
    }
}
</script>
